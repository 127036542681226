import { createTheme } from 'baseui';

const primitives = {
    baseBorderColor: '#D0D0D0',
    borderColorError: 'red',
};

const overrides = {
    colors: {
        inputFill: '#fff',
        inputPlaceholder: '#000000',
    },
    borders: {
        baseBorderWidth: '1px',
        baseBorderColor: `${primitives.baseBorderColor}`,
    },
    grid: {
        columns: [1, 2, 4],
    },
};

const lightTheme = createTheme(primitives, overrides);

export { lightTheme };
