import views from '../../components/views';

import {
    CUSTOM_PACKAGE_REQUEST_PATH,
    NO_MATCH_404_PATH,
    NO_MORE_RESPONSES_RSVP_VIEW_PATH,
    NO_MORE_RESPONSES_VIEW_PATH,
    PRIVATE_VIEW_PATH,
    RSVP_FORM_PATH,
    SUCCESS_VIEW_PATH,
    VENDOR_FORM_EXTENDED_PATH,
    VENDOR_FORM_PATH,
} from './paths';

export const VENDOR_FORM_VIEW = {
    component: views.VendorFormView,
    path: VENDOR_FORM_PATH,
    isPrivate: false,
    exact: true,
};

export const VENDOR_FORM_VIEW_EXTENDED = {
    component: views.VendorFormViewGCTheme,
    path: VENDOR_FORM_EXTENDED_PATH,
    isPrivate: false,
    exact: true,
};

export const CUSTOM_PACKAGE_REQUEST_VIEW = {
    component: views.CustomPackageRequestView,
    path: CUSTOM_PACKAGE_REQUEST_PATH,
    isPrivate: false,
    exact: true,
};

export const RSVP_FORM_VIEW = {
    component: views.RSVPFormView,
    path: RSVP_FORM_PATH,
    isPrivate: false,
    exact: true,
};

export const PRIVATE_VIEW = {
    component: views.PrivateView,
    path: PRIVATE_VIEW_PATH,
    isPrivate: true,
    exact: true,
};

export const SUCCESS_VIEW = {
    component: views.SuccessView,
    path: SUCCESS_VIEW_PATH,
    isPrivate: false,
    exact: true,
};

export const NO_MORE_RESPONSES_VIEW = {
    component: views.NoMoreResponsesView,
    path: NO_MORE_RESPONSES_VIEW_PATH,
    isPrivate: false,
    exact: true,
};

export const NO_MORE_RESPONSES_RSVP_VIEW = {
    component: views.NoMoreResponsesRSVPView,
    path: NO_MORE_RESPONSES_RSVP_VIEW_PATH,
    isPrivate: false,
    exact: true,
};

export const NO_MATCH_404_VIEW = {
    component: views.NoMatchFound404View,
    path: NO_MATCH_404_PATH,
    isPrivate: false,
    exact: false,
};

const RouteMappedComponents = [
    VENDOR_FORM_VIEW,
    VENDOR_FORM_VIEW_EXTENDED,
    PRIVATE_VIEW,
    SUCCESS_VIEW,
    NO_MORE_RESPONSES_VIEW,
    NO_MORE_RESPONSES_RSVP_VIEW,
    CUSTOM_PACKAGE_REQUEST_VIEW,
    RSVP_FORM_VIEW,
    // this should always be at the bottom
    NO_MATCH_404_VIEW,
];

export default RouteMappedComponents;
