import { lazy } from 'react';

// import all vendor components from a default export file so that lazy loading works
const VendorFormViewGCTheme = lazy(() =>
    import('./VendorForms/exports/ExportVendorFormViewExtended')
);
const VendorFormView = lazy(() => import('./VendorForms/exports/ExportVendorFormViewBasic'));

const CustomPackageRequestViewWithWrapper = lazy(() =>
    import('./CustomPackageRequest/CustomPackageRequestViewWrapper'),
);
const RSVPFormViewWrapper = lazy(() => import('./RSVPForm/RSVPFormViewWrapper'));
const PrivateView = lazy(() => import('./PrivateView'));
const SuccessView = lazy(() => import('./SuccessView'));
const NoMoreResponsesView = lazy(() => import('./NoMoreResponsesView'));
const NoMatchFound404View = lazy(() => import('./NoMatchFound404View'));
const NoMoreResponsesRSVPView = lazy(() => import('./NoMoreResponsesRSVPView'));

const Views = {
    CustomPackageRequestView: CustomPackageRequestViewWithWrapper,
    RSVPFormView: RSVPFormViewWrapper,
    VendorFormView,
    VendorFormViewGCTheme,
    PrivateView,
    NoMatchFound404View,
    SuccessView,
    NoMoreResponsesView,
    NoMoreResponsesRSVPView,
};

export default Views;
