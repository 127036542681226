import React, { useEffect } from 'react';
import { Provider as StyletronProvider } from 'styletron-react';
import { Client as Styletron } from 'styletron-engine-atomic';
import * as Sentry from '@sentry/react';
import fromEntries from 'object.fromentries';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import { BaseProvider, DarkTheme } from 'baseui';
import { lightTheme } from './theming/lightTheme';
import { Router } from 'react-router-dom';
import './App.css';
import { ThemeContext } from './contexts/theme';
import RoutesSetup from './RoutingSetup';

const engine = new Styletron();

fromEntries.shim();

const bgSurfaceColour = {
    light: '#FFF',
    dark: '#121212',
};

const history = createBrowserHistory();

// ONLY USE SENTRY IF THIS IS AN ONLINE DEPLOYMENT
if (
    process.env.REACT_APP_PAGES_DEPLOYMENT &&
    parseInt(process.env.REACT_APP_PAGES_DEPLOYMENT, 10) === 1
) {
    let environment;
    switch (process.env.REACT_APP_GIT_BRANCH_NAME) {
        case 'master':
            environment = 'development';
            break;
        case 'release':
            environment = 'staging';
            break;
        case 'production':
            environment = 'production';
            break;
        default:
            environment = 'preview';
            break;
    }
    Sentry.init({
        environment: environment,
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
            }),
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0,
    });
}

// Can be imported for doing smoke-tests for views
export function AppWithoutRouter() {
    const [theme, setTheme] = React.useState(lightTheme);
    useEffect(() => {
        // handle background colour change on theme change
        if (theme === DarkTheme) {
            document.body.style.backgroundColor = bgSurfaceColour.dark;
        } else {
            document.body.style.backgroundColor = bgSurfaceColour.light;
        }
    }, [theme]);
    useEffect(() => {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            setTheme(DarkTheme);
        }

        function handleThemeModeChange(event) {
            if (event.matches) {
                setTheme(DarkTheme);
            } else {
                setTheme(lightTheme);
            }
        }

        // add event listener
        if (window.matchMedia('(prefers-color-scheme: dark)').addEventListener) {
            window
                .matchMedia('(prefers-color-scheme: dark)')
                .addEventListener('change', handleThemeModeChange);
        }

        return () => {
            // cleanup
            if (window.matchMedia('(prefers-color-scheme: dark)').removeEventListener) {
                window
                    .matchMedia('(prefers-color-scheme: dark)')
                    .removeEventListener('change', handleThemeModeChange);
            }
        };
    }, []);

    return (
        <ThemeContext.Provider
            value={{
                setTheme,
                LightTheme: lightTheme,
                DarkTheme,
            }}
        >
            <StyletronProvider value={engine}>
                <BaseProvider theme={theme}>
                    <RoutesSetup />
                </BaseProvider>
            </StyletronProvider>
        </ThemeContext.Provider>
    );
}

// Extracted separately so that tests can use their own router history if needed
function AppWrappedWithRouter() {
    return (
        <Router history={history}>
            <AppWithoutRouter />
        </Router>
    );
}

function FallbackComponent() {
    return <div>An error has occurred</div>;
}

const myFallback = <FallbackComponent />;

function AppWrapped() {
    if (
        process.env.REACT_APP_PAGES_DEPLOYMENT &&
        parseInt(process.env.REACT_APP_PAGES_DEPLOYMENT, 10) === 1
    ) {
        return (
            <Sentry.ErrorBoundary fallback={myFallback} showDialog>
                <AppWrappedWithRouter />
            </Sentry.ErrorBoundary>
        );
    }
    return <AppWrappedWithRouter />;
}

export default AppWrapped;
