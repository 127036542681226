import React, { Suspense } from 'react';
import ProgressBarContainer from './components/presentational/ProgressBar';
import { Switch } from 'react-router-dom';
import routes from './config/routing/routes';
import AppRoute from './router/AppRoute';

function RoutesSetup() {
    return (
        <Suspense fallback={<ProgressBarContainer />}>
            <Switch>
                {routes.map((route) => (
                    <AppRoute
                        key={route.path}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                        isPrivate={route.isPrivate}
                    />
                ))}
            </Switch>
        </Suspense>
    );
}

export default RoutesSetup;
