import React from 'react';
import { Route } from 'react-router-dom';
import userIsLoggedIn from '../utils/userIsLoggedIn';

export default function AppRoute({ component: Component, path, isPrivate, exact, ...props }) {
    return (
        <Route
            path={path}
            render={(props) =>
                isPrivate && !userIsLoggedIn() ? (
                    <div>You are not logged in</div>
                ) : (
                    <Component {...props} />
                )
            }
            exact={exact}
            {...props}
        />
    );
}
