/*
 * Copyright(c) 2011-2020; Boutique Mate®
 * All rights reserved.
 * Any use or reproduction without express authorization is prohibited.
 */

import { createContext, useContext } from 'react';

export const ThemeContext = createContext({ a: 5 });
export const ThemeContextConsumer = ThemeContext.Consumer;

export function useThemeContext() {
    return useContext(ThemeContext);
}
