import * as React from 'react';
import { useState } from 'react';
import { ProgressBar } from 'baseui/progress-bar';
import { useInterval } from '../../hooks/useInterval';

export default function ProgressBarContainer({ delay = 400 }) {
    const [value, setValue] = useState(0);
    useInterval(() => {
        if (value < 100) {
            setValue(value + 10);
        } else {
            setValue(0);
        }
    }, delay);
    return <ProgressBar value={value} showLabel />;
}
